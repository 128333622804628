import React, { Component } from "react";
import $ from "jquery";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { api_url } from "../Config.js";
import { PostData } from "./PostData.js";
import checkbox from "../assets/images/checkbox-icon.png";
import swal from "sweetalert";
import save from "../assets/images/save_icon.png";
import print from "../assets/images/print-icon.png";
import email from "../assets/images/email-icon.png";
import empty_card from "../assets/images/shopping-basket.png";
class InvoiceListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
        items: JSON.parse(localStorage.getItem("cartProduct")),
        redirectToReferrer: false,
        user_id: localStorage.getItem("userData"),
            editData: JSON.parse(sessionStorage.getItem("editData"))
            ? JSON.parse(sessionStorage.getItem("editData"))
            :[],
         base_url: api_url.base_url,
         total_amount: localStorage.getItem("total_amount")
            ? localStorage.getItem("total_amount")
            : 0,
         order_no: localStorage.getItem("order_no")
            ? localStorage.getItem("order_no")
            : null,
            cartProduct:
        JSON.parse(localStorage.getItem("cartProduct")) != null
          ? JSON.parse(localStorage.getItem("cartProduct"))
          : [],
         email: "",
         redirect: false,
         loading_: false,
         loading__: false,
         loading___: false,
         loading: false,
         orderId: localStorage.getItem("orderId")
            ? localStorage.getItem("orderId")
            : "",
        discount_type: localStorage.getItem("dis_type")
        ? localStorage.getItem("dis_type")
        : 0,
        order_no: localStorage.getItem("order_no")
        ? localStorage.getItem("order_no")
        : 0,
        totalorder: localStorage.getItem("totalorder")
        ? localStorage.getItem("totalorder")
        : 0,
        discount: localStorage.getItem("discount")
        ? localStorage.getItem("discount")
        : 0,
        dis_type: localStorage.getItem("dis_type")
        ? localStorage.getItem("dis_type")
        : 0,
        customer_id: localStorage.getItem("customer_id")
        ? localStorage.getItem("customer_id")
        : 0,
        order_type: localStorage.getItem("order_type")
        ? localStorage.getItem("order_type")
        : 0,
        user_id: JSON.parse(sessionStorage.getItem("userData"))
        ? JSON.parse(sessionStorage.getItem("userData"))
        : JSON.parse(localStorage.getItem("userData")),

         status: "Pending",
         editData: [],
         suffix:'',
         decimalValue:null,
         orderToDetails:[],
         saleMan:[],
         orderType:[],
         orderDetails:[],
      };
   }

   componentDidMount() {
   const requiredURL = this.state.base_url + "sale_api/getAllAboutTheOrder/"+this.state.customer_id+"/"+this.state.user_id+"/"+this.state.order_type+"/"+this.state.order_no
    fetch(requiredURL)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            orderToDetails: result.customer,
            orderType: result.ordertype,
            saleMan: result.users,
            orderDetails:result.orderDetails
          });
        },
        (error) => {
          this.setState({ error });
        }
      );


    const decimalsUrl = this.state.base_url + "sale_api/getDecimals/"+this.state.user_id;
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
      const sufixurl = this.state.base_url + "sale_api/GetSuffix/"+this.state.user_id;
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

      $(".process_complete").show();
      $(".last_step").hide();
      $(".footer").hide();
      if (localStorage.getItem("orderId") !== null) {
         const getIdUrl =
            this.state.base_url +
            "sale_api/getBySaleOrder/" +
            localStorage.getItem("orderId");
         fetch(getIdUrl)
            .then((res) => res.json())
            .then(
               (result) => {

                  this.setState(
                     {
                        editData: result.basic_details,
                        loading: false,
                        status: result.basic_details.saleOrderStatus,
                     },
                     function () {
                        // localStorage.setItem('total_amount',((result.basic_details.total)).toFixed(0))
                        // console.log(localStorage.getItem('total_amount'))
                     }
                  );
               },
               (error) => {}
            );
      }
   }

   componentWillMount() {
      if (sessionStorage.getItem("userData")) {
         this.getUserData();
      } else if (localStorage.getItem("userData")) {
         this.getUserData();
      } else {
         this.setState({ redirectToReferrer: true });
      }
   }
   getUserData() {
      JSON.parse(sessionStorage.getItem("userData"));
      JSON.parse(localStorage.getItem("userData"));
   }
   save_records() {
      if (this.state.items === null) {
         swal("Please select some products");
      } else {
         this.setState({ loading_: true });
         PostData("save_products", this.state).then((result) => {
            if (result=="Error") {
               swal("Error occured");
              this.setState({ loading_: false });
            }else{
              this.setState({ redirect: true });
               localStorage.removeItem("total_amount");
               localStorage.removeItem("cartProduct");
               localStorage.removeItem("order_no");
               localStorage.removeItem("discount");
               localStorage.removeItem("orderId");
               localStorage.removeItem("dis_type");
            }
         });
      }
   }
   save_records_email() {
      this.setState({ loading___: true });
      let userID = this.state.customer_id;
      const apiUrl =
         this.state.base_url + `sale_api/getUserName?userId=${userID}`;
      fetch(apiUrl)
         .then((res) => res.json())
         .then(
            (result) => {
               this.setState({
                  email: result.email,
               });
               if (this.state.email !== "") {
                  PostData("save_products", this.state).then((result) => {
                     if (result) {
                        this.setState({ redirect: true });
                        localStorage.removeItem("total_amount");
                        localStorage.removeItem("cartProduct");
                        localStorage.removeItem("order_no");
                        localStorage.removeItem("discount");
                        localStorage.removeItem("orderId");
                        localStorage.removeItem("dis_type");


                     }
                  });
               }
            },
            (error) => {
               this.setState({ error });
            }
         );
   }

   printDocument() {
      this.setState({ loading__: true });
      PostData("save_products", this.state).then((result) => {
         if (result) {
            this.setState({ redirect: true });
            localStorage.removeItem("total_amount");
            localStorage.removeItem("cartProduct");
            localStorage.removeItem("order_no");
            localStorage.removeItem("discount");
            localStorage.removeItem("orderId");
            localStorage.removeItem("dis_type");
            //  $(".process_complete").hide();

            // $(".last_step").show();
            // $(".footer").show();
         }
      });
   }
   onDocumentLoadSuccess(numPages) {
      this.setState({ numPages });
   }

   onApprove(e) {
      let ischecked = e.target.checked;
      if (ischecked) {
         this.setState({ status: "Approved" });
      } else {
         this.setState({ status: "Pending" });
      }
   }



   render() {
     const {cartProduct} = this.state;
        const date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        const mydate = (this.state.orderDetails) ? this.state.orderDetails.created_on : formatedDate;
        let discountValue = 0;
        const discount_type = localStorage.getItem('dis_type');
        const discount_amount = localStorage.getItem('discount');
        if(discount_type === '1')
        {
          discountValue = discount_amount/100;
        }
        else{
          discountValue = discount_amount;
        }

        let sign = '';
        if(discount_type === '1')
        {
          sign = '%';
        }
        else{
          sign = this.state.suffix;
        }
      const { loading, loading_, loading__, loading___ } = this.state;
      const items = JSON.parse(localStorage.getItem("cartProduct"));

      function printPrice(item)
      {
        let pricevalue = 0;
        if(discount_type==='1')
          {
            pricevalue = item.unit_price * item.qty - (item.unit_price * item.qty * discountValue);
          }else{
            pricevalue  = item.unit_price * item.qty - ( item.qty * discountValue);
          }

          return pricevalue;
      }

      function grandTotalAmount() {
      var ActualValue = 0;
      var printValue = 0;
      var totalprice = 0;
      var discountValue = 0;
      var totalQty = 0;
      cartProduct.map(value=>{
        var qty = value.qty
        var unit_price = value.unit_price;
        totalprice += parseFloat(unit_price * qty);
        totalQty += value.qty;
      });

      const discount_type = localStorage.getItem('dis_type');
       const discount_amount = localStorage.getItem('discount');
       if(discount_type === '1')
       {
         discountValue = (totalprice *discount_amount/100);
         ActualValue = totalprice - discountValue;
       }
       else{
         discountValue = discount_amount;
         ActualValue = totalprice - (discountValue*totalQty);
       }


      return ActualValue;
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

      if (this.state.redirectToReferrer) {
         return <Redirect to={`/`} />;
      }
      if (this.state.redirect) {
         return <Redirect to={`/order-listing`} />;
      }
      return (
         <div>

            <div id="invoice_header">

            <div className="main_body" id="invoice_listing_p">
               <div className=" order_lists" id="order_lists_load">
                  <div className="user_login Select_language process_complete">
                     <div className="form_box select_items_pdt">
                        <h3 style={{ marginBottom: "0px" }}>Order # {this.state.totalorder} <Link className="angle_back" to='/product-detail'><i className="fa fa-angle-left"></i></Link> </h3>
                      <div className="head_amount">
                      <b>{this.state.prefix}{grandTotalAmount().toFixed(this.state.decimalValue)}{this.state.suffix}</b>
                      <p>{this.state.orderToDetails.ledgerName}</p>
                      </div>
                     </div>
                     <div className="view_history_inv">
                        <h4>Order created for {this.state.prefix}{grandTotalAmount().toFixed(this.state.decimalValue)}{this.state.suffix} </h4>
                        <p>{formatedDate + ' '+time}</p>
                     </div>
                     <div className="main_row">
                      <div className="col-8- invoice-left-right padd-left- p-padd invoice-space" id="invoice_listing_no">

                      <div className="row">
                        <div className="col-sm-8 left_invoice_list">
                          <ul>

                            <li><span>Order #</span> <b>SO-{this.state.totalorder}</b></li>
                            <li><span>Order Date</span> <b>{formatedDate}</b></li>
                            <li><span>Order Type</span> <b>{this.state.orderType.order_type_name}</b></li>
                            <li><span>Sale Man</span> <b>{this.state.saleMan.first_name+' '+this.state.saleMan.last_name}</b></li>
                        </ul>
                        </div>
                        <div className="col-sm-4 right_invoice_list">
                          <ul>

                            <li><span>Order to: </span> <b>{this.state.orderToDetails.ledgerName}</b></li>
                            <li><span>Phone:</span> <b>{this.state.orderToDetails.phone}</b></li>
                            <li><span>Address:</span> <b>{this.state.orderToDetails.address}</b></li>
                        </ul>
                        </div>
                      </div>



                      </div>


                     </div>
                     <div className="pos_inv_table ">


                      {items != null ? (
                         items.map((item, index) => {
                           var UOMNAME = '';
                           if (item.uomName !='') {
                             UOMNAME = '('+item.uomName+')'
                           }
                           var grey_class = '';
                           if (index%2!=0) {
                             grey_class = 'gray_bg_box';
                           }
                           return(
                            <div key={index} className={`row invoice_bdr ${grey_class} overdue onclic_row`}>
                              <div className="col-sm-6 left_invoice">
                                <h4>{item.pname}</h4>
                                <p>{item.qty} {}  x {item.unit_price }</p>
                                {
                                  this.state.discount > 0 &&
                                  <p>Discount : {this.state.discount} {sign}</p>
                                }

                              </div>
                              <div className="col-sm-6 right_invoice">
                                <h4>{this.state.prefix} {
                                            (printPrice(item).toFixed(this.state.decimalValue))
                                          }</h4>

                              </div>
                            </div>
                         )
                         })
                      ) : (
                         <tr>
                            <td colSpan="12">
                               <div className="empty_cart_bx">
                               <img src={empty_card} alt=""/>
                               </div>
                            </td>
                         </tr>
                      )}

                      </div>

                     <div id="invoice_listing_ftr"
                        className="total_btm-bar"
                        style={{ padding: "0px" }}
                     >
                        <div className="total-amount">
                           <button
                              className="request_door_click"
                              type="button"
                              onClick={this.save_records.bind(this)}
                              disabled={loading_}
                           >
                              {loading_ && (
                                 <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                 />
                              )}
                              {loading_ && <span>Loading...</span>}
                              {!loading_ && <span><img  src={save} /> Save</span>}
                           </button>
                           <button
                              className="save-print request_door_click"
                              type="button"
                              onClick={this.save_records.bind(this)}
                              disabled={loading__}
                           >
                              {loading__ && (
                                 <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                 />
                              )}
                              {loading__ && <span>Loading...</span>}
                              {!loading__ && <span><img  src={print} /> Save & Print</span>}
                           </button>
                           <button
                              className="eamil-print request_door_click"
                              type="button"
                              onClick={this.save_records.bind(this)}
                              disabled={loading___}
                           >
                              {loading___ && (
                                 <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                 />
                              )}
                              {loading___ && <span>Loading...</span>}
                              {!loading___ && <span><img  src={email} /> Save & Email</span>}
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="last_step">
               <img src={checkbox} alt="Check Box Icon" />
               <b>Thank You</b>
               <p>We have received your order</p>
            </div>
            <div className="footer">
            </div>
         </div>
            <div className="last_step">
               <img src={checkbox} alt="Check Box Icon" />
               <b>Thank You</b>
               <p>We have received your order</p>
            </div>
            <div className="footer">
            </div>
         </div>
      );
   }
}

export default InvoiceListing;
