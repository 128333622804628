import React, { Component } from "react";
import $ from "jquery";
import grocery from "../assets/images/grocery_graphic.png";
import checkbox from "../assets/images/checkbox-icon.png";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { api_url } from "../Config.js";
import { PostData } from "./PostData.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class TimeSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      saleman: [],
      customers: [],
      ordertype: [],
      products: [],
      totalorder: '',
      redirectToReferrer: false,
      hours: "",
      items: [],
      users: [],
      item_name: this.props.match.params.item_id,
      user_id:localStorage.getItem("userData"),
      base_url: api_url.base_url,
      validErr: "",
      startDate:null,
      redirect: false,
      customer_id: "",
      order_no: "",
      date: null,
      order_type: "",
      auto_dis: 0,
      auto_disErr: "",
      dis_perc: 1,
      loading: false,
      orderId:
        this.props.match.params.orderId !== "undefined"
          ? this.props.match.params.orderId
          : "",
      editData: [],
    };
    // window.location.reload(true)
    this.onChange = this.onChange.bind(this);
    this.validation = this.validation.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChanging = this.onChanging.bind(this);
  }

  // componentWillReceiveProps(){
  //   window.location.reload(true)
  // }

  componentDidMount() {
    //
    this.setState({ loading: true });

    const getUrl = this.state.base_url + "sale_api/getAllDetailsOnce";
    fetch(getUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState(
            {
              saleman: result.customers,
              customers: result.customers,
              ordertype: result.ordertype,
              order_type: result.ordertype.id ? result.ordertype.id:0,
              totalorder: parseFloat(result.totalorder) + parseFloat(1),
              products: result.products,
              loading: false,
            },
            function () {
              this.setState({ customer_id: this.state.customers[0].id });
            }
          );
        },
        (error) => {}
      );

    if (localStorage.getItem("orderId") !== null) {
      const getIdUrl =
        this.state.base_url +
        "sale_api/getBySaleOrder/" +
        localStorage.getItem("orderId");
      fetch(getIdUrl)
        .then((res) => res.json())
        .then(
          (result) => {

            this.setState(
              {
                editData: result.basic_details,
                loading: false,
              },
              function () {
                // localStorage.setItem('total_amount',(Math.round(result.basic_details.total)).toFixed(0))
                // console.log(localStorage.getItem('total_amount'))
              }
            );
          },
          (error) => {}
        );
    }

    let userID = this.state.user_id;
    const apiUrl =
      this.state.base_url + `sale_api/getUserName?userId=${userID}`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            users: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

    $(".request_door_click").on("click", function () {});
    $(".request_door_click").click(function () {
      $(".process_complete").hide();
    });
    $(".request_door_click").click(function () {
      $(".last_step").show();
    });
  }

  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
      this.getUserData();
    } else if (localStorage.getItem("userData")) {
      this.getUserData();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(sessionStorage.getItem("userData"));
    JSON.parse(localStorage.getItem("userData"));
  }
  onChange(e) {
    localStorage.setItem("hours", e.target.value);
    this.setState(
      { to: `/address-details/${this.props.match.params.item_id}` },
      function () {}
    );
  }

  onChanging(date) {
    this.setState({ date });
  }
  handleChange = (date) =>{
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var month = m+parseFloat(1);
    var formatedDate = y+'-'+month+'-'+d;
    this.setState({date:formatedDate})
  }

  validation() {
    if (this.state.auto_dis == "") {
      this.setState({
        auto_disErr: "* Please Enter Discount",
        redirect: false,
      });
    } else {
      return true;
      this.setState({ loading: true });
    }
  }
  refresh() {
    var disval = $("#auto_dis").val();
    var customerid = $("#customer_id").val();
    var ordertype = $("#order_type").val();
    var discountPercentage = $("#dis_perc").val();
    this.setState(
      { auto_dis: disval, customer_id: customerid, order_type: ordertype, dis_perc:discountPercentage },
      function () {
        if (this.validation() == true) {
          this.setState({ loading: true });
          // localStorage.setItem('prevState',this.state);
          // this.setState({ redirect: true });
          // PostData("basic_details", this.state).then((result) => {
            const order_not = this.state.editData.saleorderNo
                          ? this.state.editData.saleorderNo
                          : this.state.totalorder
            localStorage.setItem("order_no", order_not);
            localStorage.setItem("totalorder", this.state.totalorder);
            localStorage.setItem("discount", this.state.auto_dis);
            localStorage.setItem("dis_type", this.state.dis_perc);
            localStorage.setItem("customer_id", this.state.customer_id);
            localStorage.setItem("order_type", this.state.order_type);
            localStorage.setItem("user_id", this.state.user_id);
            localStorage.setItem("editData", JSON.stringify(this.state.editData));
            this.setState({ redirect: true });
          // });
        }
      }
    );
  }

  render() {
    // window.location.reload(true)
    const date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var month = m+parseFloat(1);
    var formatedDate = y+'-'+month+'-'+d;
    const setDate = (this.state.date !==null) ? this.state.date : formatedDate;
    const ndate = this.state.editData.deliverDate? this.state.editData.deliverDate: setDate;
    const { items, saleman } = this.state;
    const { users } = this.state.users;
    const { loading } = this.state;
    if (this.state.redirectToReferrer) {
      return <Redirect to={`/login/${this.props.match.params.orderId}`} />;
    }
    if (this.state.redirect) {
      if (localStorage.getItem("orderId")) {
        return (
          <Redirect
            to={`/product-detail/${localStorage.getItem("orderId")}`}
          />
        );
      } else {
        return <Redirect to={`/product-detail`} />;
      }
    }

    return (
      <div>
        <Header />
        <div className="main_body">
          <div className="login_screen" id="uder_eng_language">
            <div className="user_login Select_language process_complete">
              <div className="form_box_delivery">
                <h3>Sales Orders</h3>

                <div className="row">
                  <div
                    className="col-sm-12 delivery_address"
                    style={{ display: "block" }}
                  >
                    <label>Sales Order</label>
                    <input
                      type="text"
                      id="route"
                      name="street"
                      defaultValue={
                        this.state.editData.saleorderNo
                          ? this.state.editData.saleorderNo
                          : this.state.totalorder
                      }
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 delivery_address">
                    <label style={{ zIndex: 1 }}>Current Date</label>
                    <DatePicker
                     value = {ndate}
                      onChange={(e)=>this.handleChange(e)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-sm-12 delivery_address"
                    style={{ display: "block" }}
                  >
                    <label>Customer/Cash</label>
                    <select
                      id="customer_id"
                      name="customer_id"
                      onChange={(event) =>
                        this.setState({
                          customer_id: event.target.value,
                        })
                      }
                    >
                      {this.state.customers.map((o, i) => (
                        <option
                          key={i}
                          value={o.id}
                          selected={
                            this.state.editData.customerId ===
                            o.id
                          }
                        >
                          {o.ledgerName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-sm-12 delivery_address">
                    <label>Order Type</label>
                    <select
                      id="order_type"
                      name="area"
                      onChange={(event) =>
                        this.setState({
                          order_type: event.target.value,
                        })
                      }
                    >
                      {this.state.ordertype.map((o, i) => (
                        <option
                          key={i}
                          value={o.id}
                          selected={
                            this.state.editData.order_type_id ===
                            o.id
                          }
                        >
                          {o.order_type_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-8 delivery_address delivery_address_8">
                    <label>Auto Discount</label>
                    <input
                      type="text"
                      className="form-control"
                      id="auto_dis"
                      name="auto_dis"
                      value={this.state.auto_dis}
                      onChange={(event) =>
                        this.setState({
                          auto_dis: event.target.value,
                        })
                      }
                      required={true}
                      />
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {" "}
                      {this.state.auto_disErr}
                    </p>
                  </div>
                  <div className="col-sm-4 delivery_address delivery_address_4">
                    <select
                      id="dis_perc"
                      onChange={(event) =>
                        this.setState({
                          dis_perc: event.target.value,
                        })
                      }
                    >
                      <option
                        value="1"
                        defaultValue={
                          this.state.editData
                            .discount_selected_option === "1"
                        }
                      >
                        %
                      </option>
                      <option
                        value="2"
                        defaultValue={
                          this.state.editData
                            .discount_selected_option === "2"
                        }
                      >
                        Fix
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form_box Request_door" id="request_door">
                <button
                  className="button-loadings"
                  style={{ fontSize: "18px", width: "100%" }}
                  type="button"
                  onClick={this.refresh.bind(this)}
                  disabled={loading}
                >
                  {loading && (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {loading && <span>Loading...</span>}
                  {!loading && <span>Next</span>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TimeSelection;
