import React, { Component } from "react";
import $ from "jquery";
import '../assets/css/bootstrap.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/style.css';
import '../assets/css/pos.css';
import '../assets/css/font-awesome.min.css';
import grocery from "../assets/images/grocery_graphic.png";
import checkbox from "../assets/images/checkbox-icon.png";
import logo from "../assets/images/logo-eloerp.png";
import orderlist from "../assets/images/orderlist.png";
import pos from "../assets/images/pos.png";
import invoicelist from "../assets/images/invoicelist.png";
import producticon from "../assets/images/producticon.png";
import cashbook from "../assets/images/cashbook.png";
import neworder from "../assets/images/neworder.png";
import logout from "../assets/images/icons8-logout-rounded-down-96.png";

import Header from "./Header.js";
import Footer from "./Footer.js";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { api_url } from "../Config.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      redirectToReferrer: false,
      userBalance:[],
      user_id:localStorage.getItem("userData"),
        base_url: api_url.base_url,
        img_url: api_url.img_url,
        prefix: "",
        suffix: "",
        btnLoading: false,
        editData: [],
        decimalValue:0,

    };
    this.logout = this.logout.bind(this);
  }


  componentDidMount(){
    const prefixurl = this.state.base_url + "sale_api/GetPrefix";
    fetch(prefixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            prefix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
      const decimalsUrl = this.state.base_url + "sale_api/getDecimals";
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    const sufixurl = this.state.base_url + "sale_api/GetSuffix";
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
      console.log('sessionStorage is '+localStorage.getItem('userData'))
      let user_id = localStorage.getItem('userData');
      const userBalanceApi = this.state.base_url + "sale_api/userSaleDetail/"+this.state.user_id;
    fetch(userBalanceApi)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            userBalance: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
  }

  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
      this.getUserData();
    } else if (localStorage.getItem("userData")) {
      this.getUserData();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(sessionStorage.getItem("userData"));
    JSON.parse(localStorage.getItem("userData"));
  }
logout(){
sessionStorage.setItem("userData",'');
localStorage.setItem("userData",'');

this.setState({redirectToReferrer: true},function(){
});
this.setState({showLogin:'yes'},function(){
})

}
  render() {
    // window.location.reload(true)

    if (this.state.redirectToReferrer) {
      return <Redirect to={`/login/${this.props.match.params.orderId}`} />;
    }
    if (this.state.redirect) {
      if (localStorage.getItem("orderId")) {
        return (
          <Redirect
            to={`/product-detail/${localStorage.getItem("orderId")}`}
          />
        );
      } else {
        return <Redirect to={`/product-detail`} />;
      }
    }

    return (
      <div>
        <div className="dashboard_body">
          <div className="pos_main">
              <div className="pos_black_box">
                <div className="row" id="dashb_p">
                  <div className="col-sm-2 logo_box">
                  <NavLink to="/dashboard"><img src={logo} alt=""/></NavLink>
                  </div>
                  <div className="col-sm-10 search_box">
                    <div className="head_title logout_head">
                      <div className="logout_btn"><a href="#" onClick={this.logout}><img src={logout} alt=""/></a></div>
                    </div>
                  </div>
                </div>
                <div className="pos_dashboard">
                    <div className="account_bln">
                      <div className="balance_box">
                        {
                          this.state.userBalance.account_balance &&
                          <b>{this.state.prefix+this.state.userBalance.account_balance+this.state.suffix}</b>
                        }

                        <p>Account Balance</p>
                      </div>
                      <div className="row cash_flow">
                        <div className="col-sm-12 cashflow_month cash_box">
                          <h4>Cash Flow</h4>
                        </div>
                        <div className="col-sm-4 cashflow_month">
                        {
                          this.state.userBalance.total_sales_of_day &&
                          <b  className="red_value">{this.state.prefix+this.state.userBalance.total_sales_of_day+this.state.suffix}</b>
                        }
                          <p>Sale of day</p>
                        </div>
                        <div className="col-sm-4 cashflow_month">
                        {
                          this.state.userBalance.total_sales_of_month &&
                          <b  className="gren_value">{this.state.prefix+this.state.userBalance.total_sales_of_month+this.state.suffix}</b>
                        }
                          <p>Sale of month</p>
                        </div>
                        <div className="col-sm-4 cashflow_month">
                        {
                          this.state.userBalance.avg_sale &&
                          <b>{this.state.prefix+this.state.userBalance.avg_sale+this.state.suffix}</b>
                        }
                          <p>Avg sale</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 main_table_col">
                     <table className="table table-bordered table-stripped custom_table_sale">
                       <thead>
                          <tr>
                             <th>Date</th>
                             <th>V/Type</th>
                             <th>Party Name</th>
                             <th>V/No</th>
                             <th>Debit</th>
                             <th>Credit</th>
                             <th>Balance</th>
                          </tr>
                       </thead>
                       <tbody>


                          <tr>
                             <td>Mar 25 2021</td>
                             <td>Sales Invoice    </td>
                             <td>Sales Account</td>
                             <td>SI-650</td>
                             <td>120.00</td>
                             <td>120.00</td>
                             <td>120.00</td>
                          </tr>
                       </tbody>
                        <tfoot>
                            <tr>
                             <td colSpan="4">Total: </td>
                             <td>120.00</td>
                             <td>120.00</td>
                             <td>120.00</td>
                          </tr>
                        </tfoot>
                    </table>
                    </div>


                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}
export default Dashboard;
