import React, { Component } from "react";
import $ from "jquery";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { api_url } from "../Config.js";
import { PostDataPos } from "./PostDataPos.js";
import checkbox from "../assets/images/checkbox-icon.png";
import save from "../assets/images/save_icon.png";
import print from "../assets/images/print-icon.png";
import email from "../assets/images/email-icon.png";
import empty_card from "../assets/images/shopping-basket.png";
import  "../assets/css/thermal.css";
import ReactToPrint from 'react-to-print';
import swal from "sweetalert";
import ThermalInvoice from './ThermalInvoice';
class PosInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
        items: JSON.parse(localStorage.getItem("posProduct")),
        redirectToReferrer: false,
        user_id: localStorage.getItem("userData"),
        base_url: api_url.base_url,
        total_amount: 0,
        order_no: localStorage.getItem("order_no"),
        posProduct:
        JSON.parse(localStorage.getItem("posProduct")) != null
          ? JSON.parse(localStorage.getItem("posProduct"))
          : [],
         email: "",
         redirect: false,
         loading_: false,
         loading__: false,
         loading___: false,
         loading: false,
        order_no: localStorage.getItem("order_no")
        ? localStorage.getItem("order_no")
        : 0,
        totalorder: localStorage.getItem("next_pos_no")
        ? localStorage.getItem("next_pos_no")
        : localStorage.getItem("totalorder"),
        discount: localStorage.getItem("discount")
        ? localStorage.getItem("discount")
        : 0,
        dis_type: localStorage.getItem("dis_type")
        ? localStorage.getItem("dis_type")
        : 0,
        customer_id: localStorage.getItem("customer_id")
        ? localStorage.getItem("customer_id")
        : 0,
        order_type: localStorage.getItem("order_type")
        ? localStorage.getItem("order_type")
        : 0,
        user_id: JSON.parse(sessionStorage.getItem("userData"))
        ? JSON.parse(sessionStorage.getItem("userData"))
        : JSON.parse(localStorage.getItem("userData")),

         status: "Pending",
         editData: [],
         suffix:'',
         decimalValue:0,
         orderToDetails:[],
         saleMan:[],
         orderType:[],
         orderDetails:[],
         printDoc:false,
      };
   }

   componentDidMount() {
    console.table(localStorage)
   const requiredURL = this.state.base_url + "sale_api/getAllAboutTheOrder/"+this.state.customer_id+"/"+this.state.user_id+"/"+this.state.order_type+"/"+this.state.order_no+"/"+this.state.user_id
    fetch(requiredURL)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            orderToDetails: result.customer,
            orderType: result.ordertype,
            saleMan: result.users,
            orderDetails:result.orderDetails
          });
        },
        (error) => {
          this.setState({ error });
        }
      );


    const decimalsUrl = this.state.base_url + "sale_api/getDecimals/"+this.state.user_id;
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
      const sufixurl = this.state.base_url + "sale_api/GetSuffix/"+this.state.user_id;
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );

      $(".process_complete").show();
      $(".last_step").hide();
      $(".footer").hide();
      if (localStorage.getItem("orderId") !== null) {
         const getIdUrl =
            this.state.base_url +
            "sale_api/getBySaleOrder/" +
            localStorage.getItem("orderId");
         fetch(getIdUrl)
            .then((res) => res.json())
            .then(
               (result) => {

                  this.setState(
                     {
                        editData: result.basic_details,
                        loading: false,
                        status: result.basic_details.saleOrderStatus,
                     },
                     function () {
                        // localStorage.setItem('total_amount',((result.basic_details.total)).toFixed(0))
                        // console.log(localStorage.getItem('total_amount'))
                     }
                  );
               },
               (error) => {}
            );
      }
   }
   componentWillUnmount(){
    localStorage.removeItem("paid_amount");
   }
   componentWillMount() {
      if (sessionStorage.getItem("userData")) {
         this.getUserData();
      } else if (localStorage.getItem("userData")) {
         this.getUserData();
      } else {
         this.setState({ redirectToReferrer: true });
      }
   }
   getUserData() {
      JSON.parse(sessionStorage.getItem("userData"));
      JSON.parse(localStorage.getItem("userData"));
   }
   save_records() {
     var dis_type = localStorage.getItem('dis_type');
      if (this.state.items === null) {
         swal("Please select some products");
      } else {
          var grandTotalAmount = 0;
          var subTotalAmount = 0;
          var discountPpercent = 0;
          var discountAmount = 0;
          var discountTotal = 0;
          var totalDiscountAmount = 0;
          var discountValue = localStorage.getItem('discount');
          var productTotal = 0;
          var disAmount = '0';
          var fixperc = '';
        var productsArray = Array();
        this.state.posProduct.map(value=>{
            productTotal = parseFloat(value.qty) * parseFloat(value.unit_price);
            if (dis_type=="1") {
                discountPpercent = localStorage.getItem('dis_type');
                disAmount  = productTotal * discountValue / 100;
                fixperc = '%';
            }if(dis_type=="2"){
                disAmount  = discountValue;
                discountPpercent = (disAmount * 100)/productTotal;
                fixperc = 'fixed';
            }
            var totalWithDis = parseFloat(productTotal) - parseFloat(disAmount);
            grandTotalAmount += parseFloat(totalWithDis);
            subTotalAmount += parseFloat(productTotal);
            totalDiscountAmount +=parseFloat(disAmount);
          productsArray.push({
            is_stockable:value.is_stockable,
              id: value.name,
              product_id:  value.name,
              product_name: value.pname,
              UOMId: value.uom,
              UOMName: value.uomName,
              qty: value.qty,
              purchasePrice: 0,
              salePrice: value.unit_price,
              discount:discountPpercent,
              discountAmount: disAmount,
              amount: parseFloat(productTotal) - parseFloat(disAmount),
              wareHouseId: localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : 1,
            });
        })
        var payment_received = grandTotalAmount;
        if (localStorage.getItem('payment_amount') && localStorage.getItem('payment_amount') > 0) {
          payment_received = localStorage.getItem('payment_amount');
        }
        var productItemArray = []
        const date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        var is_edit=0;
        if (localStorage.getItem('is_edit')  && localStorage.getItem('is_edit') =='1') {
          is_edit = 1;
        }
        var invoice = {
          edit:is_edit,
          pos_type: localStorage.getItem('order_type'),
          customerId: localStorage.getItem('customer_id'),
          total: grandTotalAmount,
          subtotal: grandTotalAmount,
          discount: totalDiscountAmount ? totalDiscountAmount : 0,
          payment_receive: payment_received,
          balanceDue: parseFloat(grandTotalAmount) - parseFloat(payment_received),
          paymentMethod: 1,
          paymentMethodText: 'Cash',
          sale_man_id:localStorage.getItem('empolyee_id'),
          payment_method_id:1,
          invoice_date: formatedDate,
          discount_type:fixperc,
          discount_amount:totalDiscountAmount ? totalDiscountAmount : 0,
          user_id:this.state.user_id,
          created_by:this.state.user_id,
          sale_invoice_form:1,

          products:productsArray,
      }


         this.setState({ loading_: true });


         const postData = this.state.base_url+'sales/checkout';
            fetch(postData,
            {
                method: 'POST',
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(invoice)
            })
            .then((response) => response.json()
                .then((result) => {
                  localStorage.setItem("totalorder", this.state.totalorder + parseFloat(1));
                  localStorage.setItem("customer_id", 1);
                  localStorage.removeItem("total_amount");
                  localStorage.removeItem("is_edit");
                  localStorage.removeItem("posId");
                   localStorage.removeItem("posProduct");
                   localStorage.removeItem("order_no");
                   localStorage.removeItem("discount");
                   localStorage.removeItem("orderId");
                   localStorage.removeItem("dis_type");
                   localStorage.removeItem("paid_amount");
                   localStorage.setItem("order_type", 'Cart');


                   this.setState({
                  loading:false,
                  loading___:false,
                  loading_:false,
                  loading__:false,
                  redirect: true,

                });

            }))
            .catch((error) => {
              swal('Error Occured please try again')
                this.setState({

                  loading:false,
                  loading___:false,
                  loading_:false,
                  loading__:false,
                  redirect: true,

                });

                localStorage.setItem("totalorder", this.state.totalorder + parseFloat(1));
                localStorage.setItem("customer_id", 1);
                  localStorage.removeItem("total_amount");
                  localStorage.removeItem("is_edit");
                  localStorage.removeItem("posId");
                   localStorage.removeItem("posProduct");
                   localStorage.removeItem("order_no");
                   localStorage.removeItem("discount");
                   localStorage.removeItem("orderId");
                   localStorage.removeItem("dis_type");
                   localStorage.removeItem("paid_amount");
                   localStorage.setItem("order_type", 'Cart');

            });
 
      }
   }
   save_records_email() {
      this.setState({ loading___: true });
      let userID = this.state.customer_id;
      const apiUrl =
         this.state.base_url + `sale_api/getUserName?userId=${userID}`;
      fetch(apiUrl)
         .then((res) => res.json())
         .then(
            (result) => {
               this.setState({
                  email: result.email,
               });
               if (this.state.email !== "") {
                  PostDataPos("save_products_pos", this.state).then((result) => {
                     if (result) {
                        this.setState({ redirect: true });
                        localStorage.removeItem("total_amount");
                        localStorage.removeItem("is_edit");
                        localStorage.removeItem("posId");
                        localStorage.removeItem("posProduct");
                        localStorage.removeItem("order_no");
                        localStorage.removeItem("discount");
                        localStorage.removeItem("orderId");
                        localStorage.removeItem("dis_type");
                        localStorage.removeItem("paid_amount");


                     }
                  });
               }
            },
            (error) => {
               this.setState({ error });
            }
         );
   }

   printDocument() {

      this.setState({ loading__: true });
     var dis_type = localStorage.getItem('dis_type');
      if (this.state.items === null) {
         swal("Please select some products");
      } else {

          var grandTotalAmount = 0;
          var subTotalAmount = 0;
          var discountPpercent = 0;
          var discountAmount = 0;
          var discountTotal = 0;
          var totalDiscountAmount = 0;
          var discountValue = localStorage.getItem('discount');
          var productTotal = 0;
          var disAmount = '0';
          var fixperc = '';
        var productsArray = Array();
        this.state.posProduct.map(value=>{
            productTotal = parseFloat(value.qty) * parseFloat(value.unit_price);
            if (dis_type=="1") {
                discountPpercent = localStorage.getItem('dis_type');
                disAmount  = productTotal * discountValue / 100;
                fixperc = '%';
            }if(dis_type=="2"){
                disAmount  = discountValue;
                discountPpercent = (disAmount * 100)/productTotal;
                fixperc = 'fixed';
            }
            var totalWithDis = parseFloat(productTotal) - parseFloat(disAmount);
            grandTotalAmount += parseFloat(totalWithDis);
            subTotalAmount += parseFloat(productTotal);
            totalDiscountAmount +=parseFloat(disAmount);

          productsArray.push({
            is_stockable:value.is_stockable,
              id: value.name,
              product_id:  value.name,
              product_name: value.pname,
              UOMId: value.uom,
              UOMName: value.uomName,
              qty: value.qty,
              purchasePrice: 0,
              salePrice: value.unit_price,
              discount:discountPpercent,
              discountAmount: disAmount,
              amount: parseFloat(productTotal) - parseFloat(disAmount),
              wareHouseId: localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : 1,
            });
        })
        var payment_received = grandTotalAmount;
        if (localStorage.getItem('payment_amount') && localStorage.getItem('payment_amount') > 0) {
          payment_received = localStorage.getItem('payment_amount');
        }
        var productItemArray = []
        const date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        var is_edit=0;
        if (localStorage.getItem('is_edit')  && localStorage.getItem('is_edit') =='1') {
          is_edit = 1;
        }
        var invoice = {
          edit:is_edit,
          pos_type: localStorage.getItem('order_type'),
          customerId: localStorage.getItem('customer_id'),
          total: grandTotalAmount,
          subtotal: grandTotalAmount,
          discount: totalDiscountAmount ? totalDiscountAmount : 0,
          payment_receive: payment_received,
          balanceDue: parseFloat(grandTotalAmount) - parseFloat(payment_received),
          paymentMethod: 1,
          paymentMethodText: 'Cash',
          sale_man_id:localStorage.getItem('employee_id'),
          payment_method_id:1,
          invoiceDate: formatedDate,
          discount_type:fixperc,
          discount_amount:totalDiscountAmount ? totalDiscountAmount : 0,
          user_id:this.state.user_id,
          created_by:this.state.user_id,
          sale_invoice_form:1,

          products:productsArray,
      }


         this.setState({ loading__: true });


         const postData = this.state.base_url+'sales/checkout';
            fetch(postData,
            {
                method: 'POST',
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(invoice)
            })
            .then((response) => response.json()
                .then((result) => {
                  localStorage.setItem("totalorder", this.state.totalorder + parseFloat(1));
                  localStorage.setItem("customer_id", 1);
                  localStorage.removeItem("total_amount");
                  localStorage.removeItem("is_edit");
                  localStorage.removeItem("posId");
                   localStorage.removeItem("posProduct");
                   localStorage.removeItem("order_no");
                   localStorage.removeItem("discount");
                   localStorage.removeItem("orderId");
                   localStorage.removeItem("dis_type");
                   localStorage.removeItem("paid_amount");
                   localStorage.setItem("order_type", 'Cart');


                   this.setState({
                  loading:false,
                  loading___:false,
                  loading_:false,
                  loading__:false,

                });
                   this.setState({redirect:true})
            }))
            .catch((error) => {
              swal('Error Occured please try again')
                this.setState({

                  loading:false,
                  loading___:false,
                  loading_:false,
                  loading__:false,

                });

                localStorage.setItem("totalorder", this.state.totalorder + parseFloat(1));
                localStorage.setItem("customer_id", 1);
                  localStorage.removeItem("total_amount");
                  localStorage.removeItem("is_edit");
                  localStorage.removeItem("posId");
                   localStorage.removeItem("posProduct");
                   localStorage.removeItem("order_no");
                   localStorage.removeItem("discount");
                   localStorage.removeItem("orderId");
                   localStorage.removeItem("dis_type");
                   localStorage.removeItem("paid_amount");
                   localStorage.setItem("order_type", 'Cart');

            });


      }

   }
   onDocumentLoadSuccess(numPages) {
      this.setState({ numPages });
   }



   render() {
     const {posProduct} = this.state;
        const date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        const mydate = (this.state.orderDetails) ? this.state.orderDetails.created_on : formatedDate;
        let discountValue = 0;
        const discount_type = localStorage.getItem('dis_type');
        const discount_amount = localStorage.getItem('discount');
        if(discount_type === '1')
        {
          discountValue = discount_amount/100;
        }
        else{
          discountValue = discount_amount;
        }

        let sign = '';
        if(discount_type === '1')
        {
          sign = '%';
        }
        else{
          sign = this.state.suffix;
        }
      const { loading, loading_, loading__, loading___ } = this.state;
      const items = JSON.parse(localStorage.getItem("posProduct"));
      console.log(items)

      function printPrice(item)
      {
        let pricevalue = 0;
        if(discount_type==='1')
          {
            pricevalue = item.unit_price * item.qty - (item.unit_price * item.qty * discountValue);
          }else{
            pricevalue  = item.unit_price * item.qty - ( item.qty * discountValue);
          }

          return pricevalue;
      }

      function grandTotalAmount() {
      var ActualValue = 0;
      var printValue = 0;
      var totalprice = 0;
      var discountValue = 0;
      var totalQty = 0;
      posProduct.map(value=>{
        var qty = value.qty
        var unit_price = value.unit_price;
        totalprice += parseFloat(unit_price * qty);
        totalQty += value.qty;
      });

      const discount_type = localStorage.getItem('dis_type');
       const discount_amount = localStorage.getItem('discount');
       if(discount_type === '1')
       {
         discountValue = (totalprice *discount_amount/100);
         ActualValue = totalprice - discountValue;
       }
       else{
         discountValue = discount_amount;
         ActualValue = totalprice - (discountValue*totalQty);
       }


      return ActualValue;
    }

    const deci = this.state.decimalValue;
    function numberWithCommas(x) {
        return Number(parseFloat(x).toFixed(deci)).toLocaleString('en', {
            minimumFractionDigits: deci
        });
    }

      if (this.state.redirectToReferrer) {
         return <Redirect to={`/`} />;
      }
      if (this.state.redirect) {
         return <Redirect to={`/pos`} />;
      }

      return (
         <div>
             <div style={{display:'none'}}>
                 <ThermalInvoice ref={el => (this.componentRef = el)} />
             </div>
            <div id="invoice_header">

            <div className="main_body" id="invoice_listing_p">
               <div className=" order_lists" id="order_lists_load">
                  <div className="user_login Select_language process_complete">
                     <div className="form_box select_items_pdt">
                        <h3 style={{ marginBottom: "0px" }}>Invoice # {this.state.totalorder} <Link className="angle_back" to='/pos'><i className="fa fa-angle-left"></i></Link> </h3>
                      <div className="head_amount">
                      <b>{this.state.prefix}{numberWithCommas(grandTotalAmount())}{this.state.suffix}</b>
                      <p>
 

                      {
                        this.state.orderToDetails.ledgerName != '' &&
                        <p><b>{this.state.orderToDetails.ledgerName}</b></p>
                      }
                      </p>
                      </div>
                     </div>
                     <div className="view_history_inv">
                        <h4>Invoice created for {this.state.prefix}{numberWithCommas(grandTotalAmount())}{this.state.suffix} </h4>
                        <p>{formatedDate + ' '+time}</p>
                     </div>
                     <div className="main_row">
                      <div className="col-8- invoice-left-right padd-left- p-padd invoice-space">
                        <ul>

                            <li><span>Invoice #</span> <b>SI-{this.state.totalorder}</b></li>
                            <li><span>Invoice Date</span> <b>{formatedDate}</b></li>
                            <li><span>Created By</span> <b>{this.state.saleMan.first_name+' '+this.state.saleMan.last_name}</b></li>
                        </ul>
                      </div>


                     </div>
                     <div className="pos_inv_table ">


                      {items != null ? (
                         items.map((item, index) => {
                           var UOMNAME = '';
                           if (item.uomName !='') {
                             UOMNAME = '('+item.uomName+')'
                           }
                           var grey_class = '';
                           if (index%2!=0) {
                             grey_class = 'gray_bg_box';
                           }
                           return(
                            <div key={index} className={`row invoice_bdr ${grey_class} overdue onclic_row`}>
                              <div className="col-sm-6 left_invoice">
                                <h4>{item.pname}</h4>
                                <p>{numberWithCommas(item.qty)} {UOMNAME}  x {numberWithCommas(item.unit_price) }</p>
                                {
                                  this.state.discount > 0 &&
                                  <p>Discount : {this.state.discount} {sign}</p>
                                }

                              </div>
                              <div className="col-sm-6 right_invoice">
                                <h4>{this.state.prefix} {
                                            numberWithCommas(printPrice(item))
                                          }</h4>

                              </div>
                            </div>
                         )
                         })
                      ) : (
                         <tr>
                            <td colSpan="12">
                               <div className="empty_cart_bx">
                               <img src={empty_card} alt=""/>
                               </div>
                            </td>
                         </tr>
                      )}

                      </div>

                     <div id="invoice_listing_ftr"
                        className="total_btm-bar"
                        style={{ padding: "0px" }}
                     >
                        <div className="total-amount">
                           <button
                              className="request_door_click"
                              type="button"
                              onClick={this.save_records.bind(this)}
                              disabled={loading_}
                           >
                              {loading__ && (
                                 <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                 />
                              )}
                              {loading__ && <span>Loading...</span>}
                              {!loading__ && <span><img  src={save} /> Save</span>}
                           </button>
                           <ReactToPrint
                           onBeforePrint={this.printDocument.bind(this)}
                              trigger={() => {

                                return (
                                        <button
                                          className="save-print request_door_click"
                                          type="button"
                                          disabled={loading__}
                                       >
                                          {loading__ && (
                                             <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" }}
                                             />
                                          )}
                                          {loading__ && <span>Loading...</span>}
                                          {!loading__ && <span><img  src={print} /> Save & Print</span>}
                                       </button>
                                    );
                              }}
                              content={() => this.componentRef}
                            />

                           <button
                              className="eamil-print request_door_click"
                              type="button"
                              onClick={this.save_records.bind(this)}
                              disabled={loading___}
                           >
                              {loading___ && (
                                 <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                 />
                              )}
                              {loading___ && <span>Loading...</span>}
                              {!loading___ && <span><img  src={email} /> Save & Email</span>}
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="last_step">
               <img src={checkbox} alt="Check Box Icon" />
               <b>Thank You</b>
               <p>We have received your order</p>
            </div>
            <div className="footer">
            </div>
         </div>
            <div className="last_step">
               <img src={checkbox} alt="Check Box Icon" />
               <b>Thank You</b>
               <p>We have received your order</p>
            </div>
            <div className="footer">
            </div>
         </div>
      );
   }
}

export default PosInvoice;
