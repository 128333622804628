import React, { Component } from "react";
import $ from "jquery";
import '../assets/css/bootstrap.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/pos.css';
import '../assets/css/font-awesome.min.css';
import grocery from "../assets/images/grocery_graphic.png";
import checkbox from "../assets/images/checkbox-icon.png";
import logo from "../assets/images/logo-eloerp.png";
import orderlist from "../assets/images/orderlist.png";
import pos from "../assets/images/pos.png";
import invoicelist from "../assets/images/invoicelist.png";
import producticon from "../assets/images/producticon.png";
import cashbook from "../assets/images/cashbook.png";
import neworder from "../assets/images/neworder.png";
import logout from "../assets/images/icons8-logout-rounded-down-96.png";
import bottom from "../assets/images/bottom.png";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { api_url } from "../Config.js";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import "react-datepicker/dist/react-datepicker.css";
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: JSON.parse(localStorage.getItem("posProduct")),
        redirectToReferrer: false,
        user_id: localStorage.getItem("userData"),
        base_url: api_url.base_url,
        total_amount: 0,
        order_no: localStorage.getItem("order_no"),
        posProduct:
        JSON.parse(localStorage.getItem("posProduct")) != null
          ? JSON.parse(localStorage.getItem("posProduct"))
          : [],
         email: "",
         redirect: false,
         loading_: false,
         loading__: false,
         loading___: false,
         loading: false,
         redirectIt:false,
         redirectLocation:null,
        order_no: localStorage.getItem("order_no")
        ? localStorage.getItem("order_no")
        : 0,
        totalorder: localStorage.getItem("totalorder")
        ? localStorage.getItem("totalorder")
        : 0,
        discount: localStorage.getItem("discount")
        ? localStorage.getItem("discount")
        : 0,
        dis_type: localStorage.getItem("dis_type")
        ? localStorage.getItem("dis_type")
        : 0,
        customer_id: localStorage.getItem("customer_id")
        ? localStorage.getItem("customer_id")
        : 0,
        order_type: localStorage.getItem("order_type")
        ? localStorage.getItem("order_type")
        : 0,
        user_id: JSON.parse(sessionStorage.getItem("userData"))
        ? JSON.parse(sessionStorage.getItem("userData"))
        : JSON.parse(localStorage.getItem("userData")),

         status: "Pending",
         editData: [],
         suffix:'',
         decimalValue:0,
         orderToDetails:[],
         saleMan:[],
         orderType:[],
         orderDetails:[],
         printDoc:false,
         payment_amount:0,

    };
    this.logout = this.logout.bind(this);
    this.changePayment = this.changePayment.bind(this);
  }


componentDidMount() {
  console.table(localStorage)
  document.getElementById('payment_amount').focus();
   const requiredURL = this.state.base_url + "sale_api/getAllAboutTheOrder/"+this.state.customer_id+"/"+this.state.user_id+"/"+this.state.order_type+"/"+this.state.order_no+"/"+this.state.user_id
    fetch(requiredURL)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            orderToDetails: result.customer,
            orderType: result.ordertype,
            saleMan: result.users,
            orderDetails:result.orderDetails
          });
        },
        (error) => {
          this.setState({ error });
        }
      );


    const decimalsUrl = this.state.base_url + "sale_api/getDecimals/"+this.state.user_id;
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
          document.getElementById('payment_amount').focus();
        },
        (error) => {
          this.setState({ error });
        }
      );
      const sufixurl = this.state.base_url + "sale_api/GetSuffix/"+this.state.user_id;
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
          document.getElementById('payment_amount').focus();
          
        },
        (error) => {
          this.setState({ error });
        }
      );

      $(".process_complete").show();
      $(".last_step").hide();
      $(".footer").hide();
      if (localStorage.getItem("orderId") !== null) {
         const getIdUrl =
            this.state.base_url +
            "sale_api/getBySaleOrder/" +
            localStorage.getItem("orderId");
         fetch(getIdUrl)
            .then((res) => res.json())
            .then(
               (result) => {

                  this.setState(
                     {
                        editData: result.basic_details,
                        loading: false,
                        status: result.basic_details.saleOrderStatus,
                     },
                     function () {
                        
                     }
                  );
               },
               (error) => {}
            );
      }
   }

   componentWillMount() {
      if (sessionStorage.getItem("userData")) {
         this.getUserData();
      } else if (localStorage.getItem("userData")) {
         this.getUserData();
      } else {
         this.setState({ redirectToReferrer: true });
      }
   }
   getUserData() {
      JSON.parse(sessionStorage.getItem("userData"));
      JSON.parse(localStorage.getItem("userData"));
   }
    save_records() {

     var dis_type = localStorage.getItem('dis_type');
      if (this.state.items === null) {
         swal("Please select some products");
      } else {
          var grandTotalAmount = 0;
          var subTotalAmount = 0;
          var discountPpercent = 0;
          var discountAmount = 0;
          var discountTotal = 0;
          var totalDiscountAmount = 0;
          var discountValue = localStorage.getItem('discount');
          var productTotal = 0;
          var disAmount = '0';
          var fixperc = '';
        var productsArray = Array();
        this.state.posProduct.map(value=>{
            productTotal = parseFloat(value.qty) * parseFloat(value.unit_price);
            if (dis_type=="1") {
                discountPpercent = localStorage.getItem('dis_type');
                disAmount  = productTotal * discountValue / 100;
                fixperc = '%';
            }if(dis_type=="2"){
                disAmount  = discountValue;
                discountPpercent = (disAmount * 100)/productTotal;
                fixperc = 'fixed';
            }
            var totalWithDis = parseFloat(productTotal) - parseFloat(disAmount);
            grandTotalAmount += parseFloat(totalWithDis);
            subTotalAmount += parseFloat(productTotal);
            totalDiscountAmount +=parseFloat(disAmount);
          productsArray.push({
            is_stockable:value.is_stockable,
              id: value.name,
              product_id:  value.name,
              product_name: value.pname,
              UOMId: value.uom,
              UOMName: value.uomName,
              qty: value.qty,
              purchasePrice: 0,
              salePrice: value.unit_price,
              discount:discountPpercent,
              discountAmount: disAmount,
              amount: parseFloat(productTotal) - parseFloat(disAmount),
              wareHouseId: localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : 1,
            });
        })
        var productItemArray = []
        const date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        var is_edit=0;
        if (localStorage.getItem('is_edit')  && localStorage.getItem('is_edit') =='1') {
          is_edit = 1;
        }
        var invoice = {
          edit:is_edit,
          pos_type: localStorage.getItem('order_type'),
          customerId: localStorage.getItem('customer_id'),
          total: grandTotalAmount,
          subtotal: grandTotalAmount,
          discount: totalDiscountAmount ? totalDiscountAmount : 0,
          payment_receive: this.state.payment_amount,
          balanceDue: parseFloat(grandTotalAmount) - parseFloat(this.state.payment_amount),
          paymentMethod: 1,
          paymentMethodText: 'Cash',
          sale_man_id:localStorage.getItem('empolyee_id'),
          payment_method_id:1,
          invoice_date: formatedDate,
          discount_type:fixperc,
          discount_amount:totalDiscountAmount ? totalDiscountAmount : 0,
          user_id:this.state.user_id,
          created_by:this.state.user_id,
          sale_invoice_form:1,

          products:productsArray,
      }


         this.setState({ loading_: true });


         const postData = this.state.base_url+'sales/checkout';
            fetch(postData,
            {
                method: 'POST',
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(invoice)
            })
            .then((response) => response.json()
                .then((result) => {
                  localStorage.setItem("totalorder", this.state.totalorder + parseFloat(1));
                  localStorage.setItem("customer_id", 1);
                  localStorage.removeItem("total_amount");
                  localStorage.removeItem("is_edit");
                   localStorage.removeItem("posProduct");
                   localStorage.removeItem("order_no");
                   localStorage.removeItem("discount");
                   localStorage.removeItem("orderId");
                   localStorage.removeItem("dis_type");
                   localStorage.removeItem("paid_amount");
                   localStorage.setItem("order_type", 'Cart');


                   this.setState({
                  loading:false,
                  loading___:false,
                  loading_:false,
                  loading__:false,
                  redirect: true,

                });

            }))
            .catch((error) => {
              swal('Error Occured please try again')
                this.setState({

                  loading:false,
                  loading___:false,
                  loading_:false,
                  loading__:false,
                  redirect: true,

                });

                localStorage.setItem("totalorder", this.state.totalorder + parseFloat(1));
                localStorage.setItem("customer_id", 1);
                  localStorage.removeItem("total_amount");
                  localStorage.removeItem("is_edit");
                   localStorage.removeItem("posProduct");
                   localStorage.removeItem("order_no");
                   localStorage.removeItem("discount");
                   localStorage.removeItem("orderId");
                   localStorage.removeItem("dis_type");
                   localStorage.removeItem("paid_amount");
                   localStorage.setItem("order_type", 'Cart');

            });
 
      }
   }
   logout(){
    sessionStorage.setItem("userData",'');
    localStorage.setItem("userData",'');

    this.setState({redirectToReferrer: true},function(){
    });
    this.setState({showLogin:'yes'},function(){
    })

    }
    redirectIt(location){
        if (location=='pos') {
            this.setState({redirectLocation:'/pos',redirectIt:true});
        }else{
            this.setState({redirectLocation:'/pos-invoice',redirectIt:true});
            localStorage.setItem('payment_amount',this.state.payment_amount);
        }
    }
 
    changePayment(e,total){  
     

        if (e.target.value =='' || e.target.value ==isNaN) {
            this.setState({payment_amount:0});
             
        }else{
            const enterValue = e.target.value;
            if ( enterValue <= total) {
                this.setState({payment_amount:enterValue}); 
            }else{
                swal('Amount must be less than or equal to total amount');
                this.setState({payment_amount:total});
            }
        }
          
    }
  render() {
    const {posProduct} = this.state;

        const date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m+parseFloat(1);
        var formatedDate = y+'-'+month+'-'+d;
        var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        const mydate = (this.state.orderDetails) ? this.state.orderDetails.created_on : formatedDate;
        let discountValue = 0;
        const discount_type = localStorage.getItem('dis_type');
        const discount_amount = localStorage.getItem('discount');
        if(discount_type === '1')
        {
          discountValue = discount_amount/100;
        }
        else{
          discountValue = discount_amount;
        }

        let sign = '';
        if(discount_type === '1')
        {
          sign = '%';
        }
        else{
          sign = this.state.suffix;
        }
      const { loading, loading_, loading__, loading___ } = this.state;
      const items = JSON.parse(localStorage.getItem("posProduct"));

      function printPrice(item)
      {
        let pricevalue = 0;
        if(discount_type==='1')
          {
            pricevalue = item.unit_price * item.qty - (item.unit_price * item.qty * discountValue);
          }else{
            pricevalue  = item.unit_price * item.qty - ( item.qty * discountValue);
          }

          return pricevalue;
      }

      function grandTotalAmount() {
      var ActualValue = 0;
      var printValue = 0;
      var totalprice = 0;
      var discountValue = 0;
      var totalQty = 0;
      posProduct.map(value=>{
        var qty = value.qty
        var unit_price = value.unit_price;
        totalprice += parseFloat(unit_price * qty);
        totalQty += value.qty;
      });

      const discount_type = localStorage.getItem('dis_type');
       const discount_amount = localStorage.getItem('discount');
       if(discount_type === '1')
       {
         discountValue = (totalprice *discount_amount/100);
         ActualValue = totalprice - discountValue;
       }
       else{
         discountValue = discount_amount;
         ActualValue = totalprice - (discountValue*totalQty);
       }


      return ActualValue;
    }

    const deci = this.state.decimalValue;
    function numberWithCommas(x) {
        return Number(parseFloat(x).toFixed(deci)).toLocaleString('en', {
            minimumFractionDigits: deci
        });
    }

      if (this.state.redirectToReferrer) {
         return <Redirect to={`/`} />;
      }
      if (this.state.redirect) {
         return <Redirect to={`/pos`} />;
      }

      if (this.state.redirectIt) {
        return <Redirect to={this.state.redirectLocation} />
      }

    return (
      <div>
        <div className="dashboard_body" id="sale_order_page">
          <div className="pos_main">
              <div className="pos_black_box">
                <div className="row" id="dashb_p">
                  <div className="col-sm-2 logo_box">
                  <NavLink to="/dashboard"><img src={logo} alt=""/></NavLink>
                  </div>
                  <div className="col-sm-10 search_box"><div className="head_title"><h4>Checkout</h4></div></div>
                  </div>
                  <div className="pos_dashboard checkout_pos total_payment">
                  <div className="inner_qty_box">
                  <b>Total <span>{this.state.prefix}{numberWithCommas(grandTotalAmount())}{this.state.suffix}</span></b>
                  <input type="number" inputMode="decimal" onChange={(e)=>this.changePayment(e,grandTotalAmount())} placeholder="Enter Payment" className="edit_product" id="payment_amount" autocomplete="off" />
                     <b>Balance <span>{this.state.prefix}{numberWithCommas(grandTotalAmount() - parseFloat(this.state.payment_amount))}</span></b>
                    <a className="done_btn" onClick={this.save_records.bind(this)}>Submit</a>
                  </div>
                </div>
              </div>
            </div>
        </div>




        <div className="bottom-nav next_prev" >
        <div className="total_btm-bar next_prev" id="pos_total">
          <div className="total_amount_box ">
            <a onClick={()=>this.redirectIt('pos')}><button type="button" className="prev_btn"><i className="fa fa-long-arrow-left"></i> Prev</button></a>
            <a onClick={()=>this.redirectIt('pos-invoice')} ><button type="button" className="next_btn"> Next <i className="fa fa-long-arrow-right"></i>
            </button></a>
          </div>
        </div>
        </div>
      <div className="orange_fixed">
        <img src={bottom} alt="" />
      </div>
      </div>

    );
  }
}
export default Checkout;
